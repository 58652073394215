import React, { useState, useEffect, lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import LandingPage from './components/LandingPage';
import ProblemSolutions from './components/ProblemSolutions';
import Booking from './components/Booking';
import OAHelp from './components/oahelp';
import './App.css';

// Lazy load the Snowfall component
const Snowfall = lazy(() => import('react-snowfall'));

const CPSheet = lazy(() => import('./components/CPSheet'));
const CodeTemplates = lazy(() => import('./components/CodeTemplates'));

const AddSolution = lazy(() => import('./components/AddSolution'));
const AdminDashboard = lazy(() => import('./components/Admin/AdminDashboard'));

function App() {
  const [showSnow, setShowSnow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // Delay the snowfall animation
    const timer = setTimeout(() => {
      setShowSnow(true);
    }, 4000); // 3 seconds delay, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app">
      {showSnow && (
        <Suspense fallback={<div>Loading...</div>}>
          <Snowfall
            color="rgba(255, 255, 255, 0.5)"
            snowflakeCount={50}
            radius={[1, 5]}
            speed={[0.5, 3]}
            wind={[-0.5, 2]}
          />
        </Suspense>
      )}

      <Navbar toggleMenu={toggleMenu} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/problem-solutions/:problemName/:price" element={<ProblemSolutions />} />
          <Route path="/cptemplates" element={<CodeTemplates />} />
          <Route path="/cpsheet" element={<CPSheet />} />
          <Route path="/oahelp" element={<OAHelp />} />
          <Route path="/add-solution/:problemName" element={<AddSolution />} />
          <Route path="/booking" element={<Booking />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;