import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaHistory, FaLightbulb, FaBookOpen, FaChevronDown, FaChevronUp, FaCode, FaCopy } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import './EndedContestsArchive.css';

const EndedContestsArchive = () => {
  const [endedContests, setEndedContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedContest, setExpandedContest] = useState(null);
  const [expandedProblem, setExpandedProblem] = useState(null);

  useEffect(() => {
    fetchEndedContests();
  }, []);

  const fetchEndedContests = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/contests.php?action=get_ended_contests&limit=15');
      if (response.data.status === 'success') {
        setEndedContests(response.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch ended contests');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleContest = (contestId) => {
    setExpandedContest(expandedContest === contestId ? null : contestId);
    setExpandedProblem(null);
  };

  const toggleProblem = (problemId) => {
    setExpandedProblem(expandedProblem === problemId ? null : problemId);
  };

  const formatExplanation = (text) => {
    if (!text) return '';
    return DOMPurify.sanitize(text.replace(
      /(function|if|else|for|while|return)(\s+)([a-zA-Z_]\w*)/g,
      '<span class="keyword">$1</span>$2<span class="function-name">$3</span>'
    ).replace(
      /(\/\/.+)/g,
      '<span class="comment">$1</span>'
    ).replace(
      /(".*?")/g,
      '<span class="string">$1</span>'
    ).replace(
      /\n/g,
      '<br>'
    ));
  };

  const copyExplanation = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Explanation copied to clipboard!');
    });
  };

  return (
    <div className="ended-contests-archive glass-panel">
      <h2><FaHistory /> Ended Contests Archive</h2>
      {endedContests.map((contest) => (
        <div key={contest.id} className="ended-contest-item glass-card">
          <h3 onClick={() => toggleContest(contest.id)}>
            <span>{contest.contest_name}</span>
            <div className="contest-meta">
              <span className="contest-date">{new Date(contest.start_time).toLocaleDateString()}</span>
              {expandedContest === contest.id ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </h3>
          {expandedContest === contest.id && (
            <div className="contest-problems">
              {contest.problems.map((problem) => (
                <div key={problem.id} className="problem-item glass-card">
                  <h4 onClick={() => toggleProblem(problem.id)}>
                    <FaCode className="problem-icon" />
                    <span>{problem.name}</span>
                    {expandedProblem === problem.id ? <FaChevronUp /> : <FaChevronDown />}
                  </h4>
                  {expandedProblem === problem.id && (
                    <div className="problem-details">
                      <div className="hint-container">
                        <div className="hint glass-card">
                          <FaLightbulb className="hint-icon" /> 
                          <span>Hint 1: {problem.hint}</span>
                        </div>
                        {problem.hint2 && (
                          <div className="hint glass-card">
                            <FaLightbulb className="hint-icon" /> 
                            <span>Hint 2: {problem.hint2}</span>
                          </div>
                        )}
                      </div>
                      <div className="explanation glass-card">
                        <div className="explanation-header">
                          <h5><FaBookOpen /> Detailed Explanation</h5>
                        </div>
                        <div className="explanation-subheader">
                          <h6>Tips and Tricks</h6>
                          <button
                            className="copy-btn"
                            onClick={() => copyExplanation(problem.explanation)}
                          >
                            <FaCopy /> Copy
                          </button>
                        </div>
                        <div className="explanation-content" dangerouslySetInnerHTML={{ __html: formatExplanation(problem.explanation) }} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">Error: {error}</p>}
    </div>
  );
};

export default EndedContestsArchive;
