import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaShieldAlt, FaRupeeSign, FaCheckCircle, FaUpload, FaTimesCircle, FaChevronLeft, FaWifi, FaBatteryFull, FaSignal, FaQrcode, FaArrowDown, FaTimes } from 'react-icons/fa';
import './ProblemSolutions.css';

const ProblemSolutions = () => {
  const { problemName, price } = useParams();
  const navigate = useNavigate();
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const solutionPrice = parseFloat(price);
  const apiUrl = '/submit_payment.php';

  useEffect(() => {
    document.title = `Solution for ${decodeURIComponent(problemName)}`;

    // Load Tawk.to script
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/66fee7f2256fb1049b1c86fe/1i99rk478';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    // Cleanup function to remove the script when component unmounts
    return () => {
      if (s1 && s1.parentNode) {
        s1.parentNode.removeChild(s1);
      }
    };
  }, [problemName]);

  const handleScreenshotUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage('File size exceeds 5MB limit.');
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setPaymentScreenshot(file);
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setErrorMessage('');
    }
  }, []);

  const removeScreenshot = useCallback(() => {
    setPaymentScreenshot(null);
    setPreviewUrl(null);
    setErrorMessage('');
  }, []);

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    if (paymentScreenshot && userEmail) {
      setIsUploading(true);
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('problemName', problemName);
      formData.append('email', userEmail);
      formData.append('screenshot', paymentScreenshot);

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: { 
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        });
        
        if (response.data && response.data.status === 'success') {
          setPaymentSubmitted(true);
          setErrorMessage('');
        } else {
          throw new Error(response.data.message || 'Unknown error occurred');
        }
      } catch (error) {
        console.error('Error submitting payment:', error);
        setErrorMessage(error.response?.data?.message || 'An error occurred while submitting the payment. Please try again later.');
      } finally {
        setIsUploading(false);
      }
    } else {
      setErrorMessage('Please upload a screenshot and enter your email before submitting.');
    }
  };

  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const [showQrNotWorkingButton, setShowQrNotWorkingButton] = useState(false);
  const [qrImage, setQrImage] = useState('./images/payment.png');
  const [qrText, setQrText] = useState('Scan the QR code to make the payment');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowQrNotWorkingButton(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const changeQrImage = () => {
    setQrImage('./images/payment2.png');
    setQrText('Now pay on this QR code');
  };

  const [showNoEmailOption, setShowNoEmailOption] = useState(false);

  const handleNoEmailClick = () => {
    setShowNoEmailOption(true);
    setTimeout(() => setShowNoEmailOption(false), 10000); // Show for 10 seconds
  };

  const handleCloseNoEmailPopup = () => {
    setShowNoEmailOption(false);
  };

  console.log('ProblemSolutions rendered', { problemName, price }); // Add this line for debugging

  return (
    <div className="ps-container">
      <div className="ps-phone-container">
        <div className="ps-phone-frame">
          <div className="ps-phone-content">
            <div className="ps-status-bar">
              <div className="ps-status-bar-left">
                <FaSignal /> <FaWifi />
              </div>
              <div className="ps-status-bar-center">12:00</div>
              <div className="ps-status-bar-right">
                100% <FaBatteryFull />
              </div>
            </div>
            <div className="ps-header">
              <h1>Solution for {decodeURIComponent(problemName)}</h1>
            </div>
            <div className="ps-content">
              {!paymentSubmitted ? (
                <div>
                  <div className="ps-price-tag">
                    <FaRupeeSign /> {solutionPrice.toFixed(2)}
                  </div>
                  <div className="ps-assurance-messages">
                    <p><FaShieldAlt /> Your payment is 100% secure</p>
                    <p><FaCheckCircle /> Full refund if the solution doesn't work</p>
                  </div>
                  <form className="ps-payment-form" onSubmit={handlePaymentSubmit}>
                    {showQrNotWorkingButton && (
                      <button type="button" className="ps-qr-toggle-button" onClick={changeQrImage}>
                        QR not working?
                      </button>
                    )}
                    <div className="ps-qr-code-container">
                      <img src={qrImage} alt="Payment QR Code" className="ps-payment-qr-code" />
                      <p>{qrText}</p>
                    </div>
                    <div className="ps-form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        id="email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        required={!showNoEmailOption}
                        placeholder="Enter your email"
                      />
                      <p className="ps-email-alternative" onClick={handleNoEmailClick}>Don't want to use your email?</p>
                      {showNoEmailOption && (
                        <div className="ps-no-email-overlay">
                          <div className="ps-no-email-animation">
                            <button className="ps-close-popup" onClick={handleCloseNoEmailPopup}>
                              <FaTimes />
                            </button>
                            <p>Pay ₹2 extra to get solution without email</p>
                          </div>
                          <div className="ps-arrow-container">
                            <span>Send your payment screenshot here</span>
                            <FaArrowDown className="ps-arrow-icon" />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ps-form-group">
                      <label htmlFor="screenshot">Payment Screenshot:</label>
                      <div className="ps-file-input-wrapper" onClick={handleFileInputClick}>
                        <input
                          type="file"
                          id="screenshot"
                          ref={fileInputRef}
                          onChange={handleScreenshotUpload}
                          accept="image/*"
                          required
                          style={{ display: 'none' }}
                        />
                        <div className="ps-file-input-label">
                          <FaUpload /> Upload Screenshot
                        </div>
                      </div>
                    </div>
                    {previewUrl && (
                      <div className="ps-image-preview">
                        <img src={previewUrl} alt="Payment Screenshot" />
                        <button type="button" onClick={removeScreenshot} className="ps-remove-image">
                          <FaTimesCircle />
                        </button>
                      </div>
                    )}
                    {isUploading && (
                      <div className="ps-upload-progress">
                        <div className="ps-progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                        <span>{uploadProgress}% Uploaded</span>
                      </div>
                    )}
                    {errorMessage && <div className="ps-error-message">{errorMessage}</div>}
                    <button className="ps-submit-button" type="submit" disabled={isUploading}>
                      {isUploading ? 'Uploading...' : 'Submit Payment'}
                    </button>
                  </form>
                </div>
              ) : (
                <div className="ps-success-message">
                  <FaCheckCircle />
                  <p>Your payment has been submitted and is being verified. You will receive an email confirmation shortly.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add this div for Tawk.to widget */}
      <div id="tawkto-widget"></div>
    </div>
  );
};

export default ProblemSolutions;