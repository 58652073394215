import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaFileCode, FaClipboardList, FaTelegram, FaHandshake } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();
  
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const navLinks = [
        { to: '/', icon: FaHome, text: 'Home' },
        { to: '/cpsheet', icon: FaClipboardList, text: 'CP Sheet' },
        { to: '/cptemplates', icon: FaFileCode, text: 'CP Templates' },
    ];

    const handleTalkToUs = () => {
        window.open('https://t.me/+ioVcWYVASiM5ZTJl', '_blank');
    };

    const handleOAHelp = () => {
        window.open('https://t.me/cphelperoa', '_blank');
    };

    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    CPHelper
                </Link>
                <div className="menu-icon" onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </div>
                <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
                    {navLinks.map((link) => (
                        <li key={link.to} className="nav-item">
                            <Link 
                                to={link.to} 
                                className={`nav-link ${location.pathname === link.to ? 'active' : ''}`} 
                                onClick={toggleMenu}
                            >
                                <link.icon /> <span>{link.text}</span>
                            </Link>
                        </li>
                    ))}
                    <li className="nav-item">
                        <button className="glass-btn telegram-btn" onClick={handleTalkToUs}>
                            <FaTelegram /> <span>Telegram Channel</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className="oa-help-btn" onClick={handleOAHelp}>
                            <FaHandshake /> <span>OA Help</span>
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;